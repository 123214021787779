@font-face {
  font-family: "Park Lane";
  src: url('assets/fonts/ParkLaneNF.otf') format('opentype'),
    url('assets/fonts/ParkLaneNF.ttf') format('truetype');
}

@font-face {
  font-family: "Poiret";
  src:
    url('assets/fonts/PoiretOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Surveyor";
  src:
    url('assets/fonts/Surveyor-Display-Medium-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Antsy";
  src:
    url('assets/fonts/ANTSYPAN.ttf') format('truetype');
}

@font-face {
  font-family: "Winter";
  src:
    url('assets/fonts/Wintermagical-K7w9A.ttf') format('truetype');
}

@font-face {
  font-family: "Coventry";
  src:
    url('assets/fonts/CoventryGardenNF.ttf') format('truetype');
}

@font-face {
  font-family: "Architex";
  src:
    url('assets/fonts/Architex.ttf') format('truetype');
}

@font-face {
  font-family: "Uniform";
  src:
    url('assets/fonts/Uniform.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Uniform', 'Helvetica Neue', 'Surveyor', 'Park Lane', 'Poiret', 'Antsy', 'Coventry', 'Architex', 'Winter'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image {
  min-height: 100vh;
  position: relative;
  object-fit: cover;
}

.image .content {
  position: absolute;
  display: flex;
  object-fit: cover;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 100vh;
}

h3 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font: 1.3rem;
  text-align: center;
}

.text-box {
  text-align: center;
  padding: 3rem 1rem;
  text-align: justify;
}